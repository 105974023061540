import React, { useEffect, useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { Scrollbar, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import {
  LayoutSl,
  SEO2,
  CBreadCrumb,
  AssetImage,
  LWrap,
  RecruitNews,
  Vertical,
  CImgCard,
  WordPic,
  WordFull,
  ContainerCrumbs,
  InterviewPic,
  WordPicWords,
  CInterviewJumbotron,
  CQuoteBox,
  CInterviewMedia,
  CSectTitle,
  CSchedule,
  CStars,
  CLabelCard,
  CBtnList,
  LWrap2,
} from "../../../../components/_index";
import "../../../../assets/_sass/page/recruit/common.scss";
import { title } from "process";
import "../../../../assets/_sass/page/recruit/interview.scss";
import { useMobile } from "../../../../utils/use-series";

const SubPage = ({ data }: { data: any }) => {
  const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;

  const verticalData = {
    title: "堀内尚人",
    content: "Naoto Horiuchi",
    cla_left: "works_big_left",
    cla_right: "works_small_right",
    exClass: "container_left_No1",
    exClassWord: "container_left_sp  no-copy",
  };

  const verticalDataTime = {
    title: " １日のスケジュール",
    content: "",
    cla_left: "works_big_left",
    cla_right: "works_small_right",
  };
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "社員インタビュー",
      src: "/recruit/interview/",
    },
    {
      title: "堀内尚人",
      src: "/recruit/interview/article05/",
      exClass:"no-copy",
    },
  ];
  const interviewFullData1 = {
    title: "入社を決めた理由をお聞かせください",
    paragraph: [
      {
        title:
          "昔からよくお菓子作りをしていて、中学生の頃には西洋料理の調理師になろうと決め、高校卒業後に専門学校へ進学しました。専門学校2年生の時にこのホテルのアルバイト求人を目にし、興味があり申込みをしたことが始まりです。",
      },
      {
        title:
          "配属はバンケット調理。魚や肉の火入れや付合せ、エグゼクティブラウンジで提供している揚げ物など、アルバイトながらに沢山のことを教えてもらい、社員の皆さんからも本当に優しくしてもらえたため、迷わず就職先としても志望しました。",
      },
      {
        title:
          "就職活動で採用試験を受けたのは当社のみ。面接で意識したことはとにかく「元気」です。挨拶や姿勢など1年目に求められることを「しっかりできます！」とアピールしました。面接の際は他の学生に負けない声・姿勢であった自信があります。",
      },
    ],
  };
  const interviewFullData2 = {
    title: "",
    paragraph: [
      {
        title:
          "3年目からは肉や魚、ソースの仕込みも担当することに。3年目までで一通りの料理を経験することができたことは非常に良かったと感じています。積極性があれば先輩たちからもどんどん仕事を任せてもらえ、空いた時間でステーキを焼く練習を見てもらえたりと、毎日がとても楽しかったです。",
      },
      {
        title:
          "4年目にはバンケット調理へ異動となりました。会食や結婚式の前菜、エグゼクティブラウンジで提供しているオードブル2種類とアミューズ、パーティーのコールド料理を作っています。料理はどれも非常に繊細でパーツも多く、野菜はミリ単位で大きさを全て揃えます。初めは「これを一つのセクションでやるのか」と驚愕しました。",
      },
    ],
  };
  const interviewFullData3 = {
    title: "現在の所属部署で特にやりがいや達成感を感じる瞬間はどんな時ですか？",
    paragraph: [
      {
        title:
          "仕込んだことのない料理を先輩から教えてもらい、自分ひとりでもできるようになり、任せてもらえることがやりがいです。最近はテリーヌやパテ・ド・カンパーニュの仕込みに挑戦しており、「出来るようになったら率先して作っていこう」と言われたことが嬉しく、一生懸命練習しています。",
      },
    ],
  };
  const interviewFullData4 = {
    title: "仕事において、日々大切にしていることや心がけていることは何ですか？",
    paragraph: [
      {
        title:
          "“作る料理に一切の妥協をしない”。これは、自分が何よりも大切にしているポリシーです。シェフから「私達は、お客さまの想像を超えるものをお出ししなければならない」「私達は何百皿と作っているが、お客さまに届くのはそのうちの一皿。１つでも手を抜いたらそれがお客さまの手元に届き、がっかりされてしまう。」と教わったことがきっかけです。納得のいく料理を作ったうえで、最後の盛付けの際には全員でのチェックを徹底しています。",
      },
      {
        title:
          "衛生管理の面ではサービス部門にも協力してもらい、異物混入がないかの声掛けや目視の徹底も最後まで欠かせません。",
      },
    ],
  };
  const interviewFullData5 = {
    title: "職場での人間関係やチームワークについて、どのように感じていますか？",
    paragraph: [
      {
        title:
          "仕事中・仕事外問わず、先輩や上司はよく話しかけてくれます。仕込みをしているときには「こういうやり方もあるよ」と教えてくれたりもします。",
      },
      {
        title:
          "身近に高い技術を持った人がたくさんいることは非常に勉強になりますし、安心して働くことができます。",
      },
    ],
  };
  const interviewFullData6 = {
    title:
      "職場の風通しの良さや、上司とのコミュニケーションについてどう感じていますか？",
    paragraph: [
      {
        title:
          "初めて挑戦する料理については、松山総料理長から直接「こう作るんだよ」と教えてもらう機会もあります。大きなイベント前には総料理長がこだわりを皆に共有してくださり、同じものを作れるように丁寧に教えてくれます。その時は皆目を輝かせて総料理長の手元を見つめていますね。普段はフレンドリーな雰囲気で調理場にも頻繁に顔を出してくれますが、やはり料理を作る時の総料理長は顔つきも違いますし、非常にかっこ良くて憧れます。",
      },
      {
        title:
          "学生時代に専門学校で松山総料理長の授業を受け、この人のもとで働きたいと集まる学生も非常に多いですね。",
      },
    ],
  };
  const interviewFullData7 = {
    title: "今後のキャリアにおいて、どのような成長や挑戦を期待していますか？",
    paragraph: [
      {
        title:
          "もう少しバンケット調理で経験を積みたいと思っています。ソシエやブッチャー調理も経験してみたいですし、鉄板焼での勤務にも憧れます。ひとりで目の前のお客さまを相手に調理や会話をするのがかっこ良いなと思うので、いずれは経験してみたいです。",
      },
      {
        title:
          "将来はシェフになることが目標です。もちろん大変なことはたくさんあると思いますが、やるからには上を目指したいと考えています。",
      },
    ],
  };

  const interviewFullData8 = {
    title:
      "ホテル業界で働くことの魅力は何だと感じますか？他の業界と比較して、ホテル業界ならではの良さは何ですか？",
    paragraph: [
      {
        title:
          "ホテルであれば期間限定・季節限定のメニューを多く提供できることが魅力の一つだと思います。ホテルは大量調理をおこなうためたくさんの食材の仕入れも可能ですし、協力会社の方からもおすすめの野菜などを教えてもらい勉強になります。",
      },
      {
        title:
          "ソースを作る担当やスープ担当、魚をおろす担当など、役割が明確に分かれているので短期間でスキルを身に付け成長できることも特徴だと思います。ひとつひとつの環境でしっかり学ぶことができ、それぞれの場所で教わった技術が今では自分の武器にもなっています。",
      },
    ],
  };

  const interviewFullData9 = {
    title:
      "これから当社に応募を考えている方々に向けて、何かアドバイスやメッセージをお願いします",
    paragraph: [
      {
        title:
          "実際にホテルに足を運び、まずは雰囲気を知ってほしいです。入社後の「思っていたホテルと違う」を防ぐためにも、気になっているホテルや良いなと思うホテルは積極的に見に行くことをお勧めします。",
      },
    ],
  };

  const picData1 = {
    img: {
      src: "/assets/images/recruit/interview/article05/interview_article05_02.png",
      alt: "",
    },
    title: "入社後の仕事内容を教えてください",
    paragraph: [
      {
        title:
          "入社後はレストラン「シェフズダイニング シンフォニー」に配属となり、アルバイトの時とは全く違う部署だったのではじめはあたふたしていました。そんな中でも毎日できることが増えていくごとに自身の成長を感じていました。レストランにはいろいろなポジションがあり、次のステップが明確に見えるため、「次のポジションを目指して頑張ろう」と同期と切磋琢磨しながら働いていました。最初はサラダやサンドウィッチ、ソーセージ盛り合わせ、チーズを担当。2年目からはメイン料理の付合せ、パスタ・ピラフ・カレー等を担当しました。",
      },
    ],
    subcontent: "",
    // position: true,
    positionSp: true,
  };

  const picData2 = {
    img: {
      src: "/assets/images/recruit/interview/article05/interview_article05_03.png",
      alt: "",
    },
    title: "ロイヤルパークホテルの職場の雰囲気を一言で表現すると？",
    paragraph: [
      {
        title:
          "一言でいうと「働きやすい」です。違うセクションに行っても快く挨拶を返してくれますし発注ミス等のトラブルがあった際にもセクションを越えて助け合っています。壁はほとんど感じませんね。",
      },
      {
        title:
          "普段は和気あいあいとしていますが、仕事をしているときはもちろん皆集中し、「やる時はやる」という雰囲気。メリハリがあるので出勤すると自分もスイッチが入ります。忙しくて皆が余裕のない時こそ、大きな声で挨拶することを心がけています。",
      },
    ],
    subcontent: "",
    position: true,
  };
  const picData3 = {
    img: {
      src: "/assets/images/recruit/interview/article05/interview_article05_04.png",
      alt: "",
    },
    title:
      "当社で働く中で、これまで最も達成感や充実感を感じた瞬間は何でしたか？",
    paragraph: [
      {
        title:
          "クリスマスからお正月期間、膨大な量の仕込みやおせちの準備を仲間と協力して行い、やり切った瞬間は非常に達成感を感じました。",
      },
      {
        title:
          "また2023年6月にはクラブ・プロスペール・モンタニェ日本支部が主催する「フランス料理最優秀見習い料理人選抜コンクール」に出場しました。およそ3か月の間、1日3～4時間の練習を重ねました。総料理長にも先輩にも指導をしてもらい、練習は本当に大変でしたが、結果は肉料理部門の「ヴィアンド賞」を受賞。",
      },
      {
        title:
          "なかなか経験できないことですし、賞に選ばれたときは本当に嬉しかったです！",
      },
    ],
    subcontent: "",
    position: true,
  };
  const picData4 = {
    img: {
      src: "/assets/images/recruit/interview/article05/interview_article05_04.png",
      alt: "",
    },
    title: "仕事を通じて成長した部分を教えてください",
    paragraph: [
      {
        title:
          "実際にホテルに足を運び、まずは雰囲気を知ってほしいです。入社後の「思っていたホテルと違う」を防ぐためにも、気になっているホテルや良いなと思うホテルは積極的に見に行くことをお勧めします。",
      },
      {
        title:
          "私も専門学生の時にホテルを実際に見学して、ロイヤルパークホテルに魅力を感じました。レストランで食べた料理がとても美味しくて、接客も良かったことが印象に残っています。",
      },
      {
        title:
          "そしてとにかく、元気に挨拶できる新入社員と働きたいですね。一日のはじまりの「おはようございます」の印象は非常に大切です。勇気を出して積極的に挨拶をしてくれるとこちらも答えたくなります！あとは先輩や同期から何でも吸収し、成長しようという姿勢も大事だと思います。",
      },
    ],
    subcontent: "",
    position: true,
  };

  const picData5 = {
    img: {
      src: "/assets/images/recruit/interview/article05/interview_article05_05.png",
      alt: "",
    },
    title: "",
    paragraph: [
      {
        title:
          "実際にホテルに足を運び、まずは雰囲気を知ってほしいです。入社後の「思っていたホテルと違う」を防ぐためにも、気になっているホテルや良いなと思うホテルは積極的に見に行くことをお勧めします。",
      },
      {
        title:
          "私も専門学生の時にホテルを実際に見学して、ロイヤルパークホテルに魅力を感じました。レストランで食べた料理がとても美味しくて、接客も良かったことが印象に残っています。",
      },
      {
        title:
          "そしてとにかく、元気に挨拶できる新入社員と働きたいですね。一日のはじまりの「おはようございます」の印象は非常に大切です。勇気を出して積極的に挨拶をしてくれるとこちらも答えたくなります！あとは先輩や同期から何でも吸収し、成長しようという姿勢も大事だと思います。",
      },
    ],
    subcontent: "",
    position: true,
  };
  const carouselData = [
    {
      img: {
        src: "/assets/images/recruit/interview/interview.png",
        alt: "",
      },
      title: "ザ ロイヤルパークホテル アイコニック 東京汐留 フロントチーム",
      content: (
        <>
          「人と関わる」を極めるためアニメ制作の道からホテルマンに軌道修正。
          <br />
          入社10年目を前に改めて「意思疎通や連携を大事にする会社」と実感
        </>
      ),

      end_title: "2015年 新卒入社",
      jumpUrl: "/recruit/interview/article01/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview2.png",
        alt: "",
      },
      title: "丸ノ内ホテル 料飲　(レストラン ポム・ダダン) ",
      content: (
        <>
          お客さまの大切な時間を共有できるのがレストラン部門の醍醐味。
          <br />
          価値ある取り組みを紹介する「真心感動ボード」も仕事への意欲に
        </>
      ),
      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article02/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview3.png",
        alt: "",
      },
      title: "ザ ロイヤルパーク キャンバス 大阪北浜 フロントチーム",
      content: (
        <>
          「さまざまな接客を経験したい」と、これまで経験のないフロント業務ができる当社へ。ライフスタイルホテルの最高峰を目指し、若いスタッフの意見を採り入れてイベントを実施する
        </>
      ),

      end_title: "2019年 キャリア入社",
      jumpUrl: "/recruit/interview/article03/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview4.png",
        alt: "",
      },
      title: "ザ ロイヤルパークホテル アイコニック 大阪御堂筋 フロントチーム",
      content: (
        <>
          学生時代に宿泊したホテルのサービスに感動し、ホテル業界で働きたいと当社へ就職。お客さまやスタッフとのコミュニケーションを高め、おもてなしの最高峰を極める
        </>
      ),

      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article04/",
    },

    {
      img: {
        src: "/assets/images/recruit/interview/interview5.png",
        alt: "",
      },
      title:
        "ロイヤルパークホテル（東京・日本橋）調理部 洋食調理二課 バンケットコールド調理",
      content: (
        <>
          作る料理に一切の妥協をしない。
          <br />
          コンクールにも挑戦し、自分の武器を増やしながら成長を重ねる毎日
        </>
      ),
      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article05/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview6.png",
        alt: "",
      },
      title: "仙台ロイヤルパークホテル 婚礼",
      content: (
        <>
          「不採用でもあきらめない」
          <br />
          子どもの頃の憧れを形に掴んだキャリア
        </>
      ),

      end_title: "2020年 キャリア入社",
      jumpUrl: "/recruit/interview/article06/",
    },
  ];
  return (
    <LayoutSl isKv={false}>
      <SEO2
        title={frontmatter?.title}
        description={frontmatter?.description}
        isTop={false}
      />

      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="new_main">
            <ContainerCrumbs data={crumbsData} />
            <div className="container_main container_main_interview ">
              <Vertical data={verticalData} />
              <div className="container_right interview-container-right">
                <AssetImage
                  src="/assets/images/recruit/interview/article05/interview_article05.png"
                  alt=""
                  loading="lazy"
                  wrapClassName="article-pc"
                />

                {isSp && (
                  <AssetImage
                    src="/assets/images/recruit/interview/article05/interview-article05-media.png"
                    alt=""
                    loading="lazy"
                    wrapClassName="article-media"
                  />
                )}
                <div className="interview_title">
                  <p className="m_title_message m_title_message-media">
                    作る料理に一切の妥協をしない。
                    <br />
                    コンクールにも挑戦し、
                    <br />
                    自分の武器を増やしながら成長を重ねる毎日
                  </p>
                </div>
                <div className="interview_subtitle">
                  <p className="m_subtitle_message m_title_subtitle-media">
                    2020年 新卒入社
                    <br />
                    ロイヤルパークホテル（東京・日本橋）調理部 洋食調理二課 <br className="u_sp" />
                    バンケットコールド調理
                  </p>
                </div>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="person-article-div">
            <div className="new_main">
              <WordFull
                data={interviewFullData1}
                exClass="container_main_message_full2"
              />
              <WordPic
                data={picData1}
                exClass="container_message_picture_same container_main_message_bisect2"
              />
              <WordFull
                data={interviewFullData2}
                exClass="container_main_message_full2 mt--7-sp"
              />

              {/* <div className="container_main_message_full  container_main_message_full2">
              <div className="message_content2 ">
                <p className="message_paragraph_small">
                  3年目からは肉や魚、ソースの仕込みも担当することに。3年目までで一通りの料理を経験することができたことは非常に良かったと感じています。積極性があれば先輩たちからもどんどん仕事を任せてもらえ、空いた時間でステーキを焼く練習を見てもらえたりと、毎日がとても楽しかったです。
                </p>
                <p className="message_paragraph_small">4年目にはバンケット調理へ異動となりました。会食や結婚式の前菜、エグゼクティブラウンジで提供しているオードブル2種類とアミューズ、パーティーのコールド料理を作っています。料理はどれも非常に繊細でパーツも多く、野菜はミリ単位で大きさを全て揃えます。初めは「これを一つのセクションでやるのか」と驚愕しました。
                </p>
              </div>
            </div> */}
              <WordFull
                data={interviewFullData3}
                exClass="container_main_message_full2"
              />
              <WordFull
                data={interviewFullData4}
                exClass="container_main_message_full2"
              />

              <WordPic
                data={picData2}
                exClass="container_message_picture_same container_main_message_bisect2"
                exRightClass="article05-p2-p"
              />
              <WordFull
                data={interviewFullData5}
                exClass="container_main_message_full2"
              />
              <WordFull
                data={interviewFullData6}
                exClass="container_main_message_full2"
              />
              <WordFull
                data={interviewFullData7}
                exClass="container_main_message_full2"
              />
              <WordPic
                data={picData3}
                exRightClass="right-pic-media-left"
                exClass="container_main_message_bisect2"
              />
              <WordFull
                data={interviewFullData8}
                exClass="container_main_message_full2"
              />
              <WordFull
                data={interviewFullData9}
                exClass="container_main_message_full2"
              />
              <WordPic
                data={picData5}
                exClass="container_message_picture_same container_main_message_bisect2"
                exRightClass="article05-p5-p"
              />
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="l_sect04  u_grey recruit-font">
        <LWrap2>
          <div className="new_main u_grey">
            <div className="container_main container_main_time container_main_time-media">
              <Vertical data={verticalDataTime} />
              <div className="demo2 article01-mythBox">
                <div className="timeboxItem01">
                  <div className="mythBox">
                    <div className="timebox-left">
                      <p className="timebox-title"></p>
                      <div className="timeline-item">
                        <i className="timeline-axis bg5-01"></i>
                        <div className="timeline-content text timeline-content-media">
                          <div className="timeline-detail">
                            {" "}
                            <span className="time_span_title_left">08:30 </span>
                            <span className="time_span_title_right article-pc">
                              出勤
                              <br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              会食・パーティーの予約に合わせ盛込み作業
                            </span>
                          </div>
                          <div className="timeline-title01 ">
                            <div className="article-timeline-media timeline-media-wd fs-14-28-sp">
                              出勤
                              <br />
                              会食・パーティーの予約に合わせ盛込み作業
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <i className="timeline-axis bg5-02"></i>
                        <div className="timeline-content text timeline-content-media">
                          <div className="timeline-detail">
                            <span className="time_span_title_left">11:00 </span>
                            <span className="time_span_title_right  article-pc">
                              エグゼクティブラウンジの料理仕込み
                            </span>
                          </div>
                          <div className="timeline-title01">
                            <div className="article-timeline-media timeline-media-wd fs-14-28-sp">
                              エグゼクティブラウンジの料理仕込み
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <i className="timeline-axis bg5-03"></i>
                        <div className="timeline-content text timeline-content-media">
                          <div className="timeline-detail">
                            <span className="time_span_title_left">13:00 </span>
                            <span className="time_span_title_right article-pc">
                              休憩
                            </span>
                          </div>
                          <div className="timeline-title01">
                            <div className="article-timeline-media timeline-media-wd fs-14-28-sp">
                              休憩
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <i className="timeline-axis bg5-04"></i>
                        <div className="timeline-content text timeline-content-media">
                          <div className="timeline-detail">
                            <span className="time_span_title_left">14:00 </span>
                            <span className="time_span_title_right article-pc">
                              翌日使用する野菜や肉、魚の火入れ
                              <br />
                            </span>
                            <span className="time_span_title_right1 article-pc">
                              フルーツ、サーモン、ソーセージの仕込み
                            </span>
                          </div>
                          <div className="timeline-title01">
                            <div className="article-timeline-media timeline-media-wd fs-14-28-sp">
                              翌日使用する野菜や肉、魚の火入れ
                              <br />
                              フルーツ、サーモン、ソーセージの仕込み
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <i className="timeline-axis bg5-05"></i>
                        <div className="timeline-content text timeline-content-media">
                          <div className="timeline-detail">
                            <span className="time_span_title_left">16:00 </span>
                            <span className="time_span_title_right article-pc">
                              衛生点検（床掃除、ゴミ出し、冷蔵庫内の整理整頓、賞味期限チェック）
                            </span>
                          </div>
                          <div className="timeline-title01">
                            <div className="article-timeline-media timeline-media-wd fs-14-28-sp">
                              衛生点検（床掃除、ゴミ出し、冷蔵庫内の整理
                              <br className="u_sp" />
                              整頓、賞味期限チェック）
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <i className="timeline-axis bg5-06"></i>
                        <div className="timeline-content text timeline-content-media">
                          <div className="timeline-detail">
                            <span className="time_span_title_left">17:00 </span>
                            <span className="time_span_title_right article-pc">
                              退勤
                            </span>{" "}
                          </div>
                          <div className="timeline-title01">
                            <div className="article-timeline-media interview_a1_timebox-right-media fs-14-28-sp">
                              退勤
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>

      <section className="l_sect04 recruit-font">
        <LWrap2>
          <div className="swiper swiper_buttons swiper_buttons_media">
            <div className="swiper-button-prev turnPrev"></div>&nbsp;
            <div className="swiper-button-next turnNext"></div>
          </div>
          <div className="new_main">
            <div className="container_main_carouse">
              {isSp ? (
                <>
                  <div className="carouse_right article-media article-swiper-media">
                    <Swiper
                      autoplay={false}
                      loop={false}
                      modules={[Scrollbar, Autoplay, Navigation]}
                      speed={1000}
                      className="c_labelCardSlider"
                      slidesPerView={1}
                      centeredSlides={false}
                      breakpoints={{
                        "768": {
                          spaceBetween: 0,
                        },
                        "769": {
                          spaceBetween: 50,
                        },
                      }}
                      spaceBetween={50}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                    >
                      {carouselData.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <div className="carouse_body">
                              <Link to={item.jumpUrl}>
                                <AssetImage
                                  src={`${item.img.src}`}
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="carouse_title">
                                  {item.title}
                                </div>
                                <div className="carouse_content">
                                  <p>{item.content}</p>
                                </div>
                                <div className="carouse_end">
                                  {item.end_title}
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </>
              ) : (
                <>
                  <div className="carouse_right article-pc">
                    <Swiper
                      autoplay={false}
                      loop={false}
                      modules={[Scrollbar, Autoplay, Navigation]}
                      speed={1000}
                      className="c_labelCardSlider"
                      slidesPerView={3.5}
                      centeredSlides={false}
                      breakpoints={{
                        "768": {
                          spaceBetween: 0,
                        },
                        "769": {
                          spaceBetween: 50,
                        },
                      }}
                      spaceBetween={50}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                    >
                      {carouselData.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <div className="carouse_body">
                              <Link to={item.jumpUrl}>
                                <AssetImage
                                  src={`${item.img.src}`}
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="carouse_title">
                                  {item.title}
                                </div>
                                <div className="carouse_content">
                                  <p>{item.content}</p>
                                </div>
                                <div className="carouse_end">
                                  {item.end_title}
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="return_overview_class">
            {isSp ? (
              <>
                <Link to="/recruit/interview/" target="_blank">
                  <div className="return_overview_class_internal return-overview-media">
                    <div className="btn-list">一覧に戻る</div>
                    <div className="circle-icon">
                      <img
                        src="/assets/images/recruit/common/icon-new-right.png"
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              </>
            ) : (
              <>
                <div className="return_overview_class_internal return-overview-media hover_active">
                  一覧に戻る
                  <a href="/recruit/interview/">
                    <img
                      src="/assets/images/recruit/goto.svg"
                      className="return_overview_images"
                    />
                  </a>
                </div>
              </>
            )}
          </div>
        </LWrap2>
      </section>
    </LayoutSl>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
